<template>
  <div class="promotion">
    <main>
      <div class="user_info">
        <div class="info">
          <img
            :src="
              isLogin && userInfo.userDetailEntity.profilePicture
                ? userInfo.userDetailEntity.profilePicture
                : '../../assets/images/portrait_icon.png'
            "
            class="avatar"
          />
          <div>
            <p class="tel">{{ userInfo.userEntity.username | Tel }}</p>
            <div class="Lv">
              当前等级：
              <div
                :style="[
                  { width: '82px' },
                  { height: '24px' },
                  {
                    backgroundImage:
                      promoterInfo && promoterInfo.level < 5
                        ? `url('${require('@/assets/images/lv_sprites.png')}')`
                        : '',
                  },
                  {
                    backgroundPosition: promoterInfo
                      ? promoterInfo.level === 1
                        ? '-10px -10px'
                        : promoterInfo.level === 2
                        ? '-10px -54px'
                        : promoterInfo.level === 3
                        ? ' -112px -10px'
                        : promoterInfo.level === 4
                        ? '-112px -54px'
                        : ''
                      : '',
                  },
                ]"
              ></div>
            </div>
          </div>
        </div>
        <p
          class="upgrades_tips"
          v-if="promoterInfo && promoterInfo.level < maxLevel"
        >
          当前等级为L<span>{{ promoterInfo && promoterInfo.level }}</span
          >，则【推广业绩还需
          <b>
            <span style="font-weight: normal">￥</span>
            {{
              promoterInfo && promoterInfo.neededPerformance
                ? promoterInfo.neededPerformance.toFixed(2)
                : ""
            }}
          </b>
          ，升级到L
          <span>{{ promoterInfo && promoterInfo.level + 1 }}</span>
          赚更多等级佣金】
        </p>
      </div>
      <div class="box promotion_data">
        <div class="accumulate">
          <div>
            <p>
              累计赚佣（元）<svg-icon
                :icon-class="eyesShow ? 'open_eyes2' : 'close_eyes'"
                @click="eyesShow = !eyesShow"
              />
            </p>
            <h3>
              {{
                eyesShow
                  ? promoterInfo && promoterInfo.commission
                    ? promoterInfo.commission.toFixed(2)
                    : 0
                  : "*****"
              }}
            </h3>
          </div>
          <van-button plain round color="#108BFF">
            去提现<svg-icon icon-class="arrow_right" />
          </van-button>
        </div>
        <div class="commission">
          <div>
            <p class="text">待结算佣金（元）</p>
            <p class="price">
              {{
                eyesShow
                  ? promoterInfo && promoterInfo.beSettledCommission
                    ? promoterInfo.beSettledCommission.toFixed(2)
                    : 0
                  : "*****"
              }}
            </p>
          </div>
          <div>
            <p class="text">已结算佣金（元）</p>
            <p class="price">
              {{
                eyesShow
                  ? promoterInfo && promoterInfo.settledCommission
                    ? promoterInfo.settledCommission.toFixed(2)
                    : 0
                  : "*****"
              }}
            </p>
          </div>
        </div>
        <div class="performance">
          <div>
            <p class="text">累计推广业绩</p>
            <p class="num">
              {{
                eyesShow
                  ? promoterInfo && promoterInfo.performance
                    ? promoterInfo.performance.toFixed(2)
                    : 0
                  : "*****"
              }}
            </p>
          </div>
          <div>
            <p class="text">累计推广订单</p>
            <p class="num">
              {{
                eyesShow
                  ? promoterInfo && promoterInfo.orderNumber
                    ? promoterInfo.orderNumber
                    : 0
                  : "*****"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="box add_commission">
        <div class="date">
          <p
            v-for="(item, i) in date"
            :key="i"
            :style="i === dateVal ? 'color:#fff;background:#108BFF' : ''"
            @click="selectDate(i)"
          >
            {{ item.text }}
          </p>
        </div>

        <div class="content">
          <p class="text">新增赚佣（元）</p>
          <b class="num">{{
            promoterInfo && promoterInfo.newlyCommission
              ? promoterInfo.newlyCommission.toFixed(2)
              : 0
          }}</b>
          <div class="to_be_settled">
            <span class="text">含待结算：</span
            ><span class="price">{{
              promoterInfo && promoterInfo.newlyBeSettledCommission
                ? promoterInfo.newlyBeSettledCommission.toFixed(2)
                : 0
            }}</span>
          </div>
          <div class="performance">
            <div>
              <p class="text">新增推广业绩</p>
              <b class="num">{{
                promoterInfo && promoterInfo.newlyPerformance
                  ? promoterInfo.newlyPerformance.toFixed(2)
                  : 0
              }}</b>
            </div>
            <div>
              <p class="text">新增推广订单</p>
              <b class="num">{{
                promoterInfo && promoterInfo.newlyOrderNumber
                  ? promoterInfo.newlyOrderNumber
                  : 0
              }}</b>
            </div>
          </div>
          <transition name="van-fade">
            <van-loading color="#108BFF" v-show="dateLoading" />
          </transition>
        </div>
      </div>
      <div class="my_promotion_order">
        <span>我的推广订单</span>
        <router-link to="promotion/promotionOrder"
          >查看<svg-icon icon-class="arrow_right"
        /></router-link>
      </div>
      <router-link
        to="promotion/nounInterpretation"
        class="see_noun_explanation"
        >查看名词解释 <svg-icon icon-class="arrow_right"
      /></router-link>
    </main>
    <transition name="van-fade">
      <div class="share_tips" v-show="shareTipsShow">
        <div>
          <p>今日无推广数据，分享商品到微信群，喊亲朋好友下单吧~</p>
          <button>去分享</button>
          <svg-icon icon-class="close" @click="shareTipsShow = false" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatDate } from "@/utils/validate";
export default {
  name: "promotion",
  data() {
    return {
      date: [
        {
          text: "今日",
        },
        {
          text: "昨日",
        },
        {
          text: "本月",
        },
        {
          text: "上月",
        },
      ],
      dateVal: 0,
      dateLoading: true,
      shareTipsShow: true,
      eyesShow: true,
      promoterInfo: null,
      maxLevel: null,
    };
  },
  computed: {
    ...mapState("user", ["isLogin", "userInfo", "promoter"]),
  },
  filters: {
    Tel(val) {
      return val.substr(0, 3) + "****" + val.substr(7);
    },
  },
  created() {
    if (!this.isLogin) {
      this.$router.replace(`/login?redirect_uri=/promotion`);
      return;
    }
    if (!this.promoter) {
      this.$router.replace("/promotion/promotionRegistration");
      return;
    }

    this.getDate();
    this.getPromoterInfo();
    this.getCommissionRuleDetails();
  },

  methods: {
    async getPromoterInfo() {
      await this.$API.user
        .promoterInfo({
          object: {
            startTime: this.date[this.dateVal].startTime,
            endTime: this.date[this.dateVal].endTime,
            id: this.promoter.userId,
          },
        })
        .then((res) => {
          this.dateLoading = false;
          this.promoterInfo = res.data.data;

          if (this.promoterInfo.newlyCommission && !this.dateVal)
            this.shareTipsShow = false;
        })
        .catch((e) => {
          this.dateLoading = false;
          console.log(e);
        });
    },
    async getCommissionRuleDetails() {
      await this.$API.user
        .commissionRuleDetails({
          object: {},
        })
        .then((res) => {
          if (res.data.code != "SUCCESS") throw Error(res.data.msg);
          this.maxLevel = res.data.data[res.data.data.length - 1].level;
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
    getDate() {
      // 获取当前时间戳
      var currentTimestamp = Date.now();
      // 获取今日0点时间
      var todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      // 获取今日0点时间戳（毫秒级）
      var todayStartTimestamp = todayStart.getTime();
      // 获取昨日0点时间
      var yesterdayStart = new Date(todayStartTimestamp - 24 * 60 * 60 * 1000);
      // 获取昨日0点时间戳（毫秒级）
      var yesterdayStartTimestamp = yesterdayStart.getTime();
      // 获取本月1号0点时间
      var currentMonthStart = new Date();
      currentMonthStart.setDate(1);
      currentMonthStart.setHours(0, 0, 0, 0);
      // 获取本月底0点时间
      var currentMonthEnd = new Date(
        currentMonthStart.getFullYear(),
        currentMonthStart.getMonth() + 1,
        0
      );
      currentMonthEnd.setHours(0, 0, 0, 0);
      // 获取本月1号0点时间戳（毫秒级）
      var currentMonthStartTimestamp = currentMonthStart.getTime();
      // 获取本月底0点时间戳（毫秒级）
      var currentMonthEndTimestamp = currentMonthEnd.getTime();

      // 获取当前时间
      var today = new Date();
      // 获取上个月的月份和年份
      var lastMonthYear =
        today.getMonth() === 0 ? today.getFullYear() - 1 : today.getFullYear();
      var lastMonthMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;

      // 获取上个月的1号和月底日期对象
      var lastMonthStart = new Date(lastMonthYear, lastMonthMonth, 1);
      var lastMonthEnd = new Date(lastMonthYear, lastMonthMonth + 1, 0);

      // 获取上个月的1号和月底的时间戳（毫秒级）
      var lastMonthStartTimestamp = lastMonthStart.getTime();
      var lastMonthEndTimestamp = lastMonthEnd.getTime();

      this.date[0].startTime = todayStartTimestamp;
      this.date[0].endTime = currentTimestamp;

      this.date[1].startTime = yesterdayStartTimestamp;
      this.date[1].endTime = todayStartTimestamp;

      this.date[2].startTime = currentMonthStartTimestamp;
      this.date[2].endTime = currentMonthEndTimestamp;

      this.date[3].startTime = lastMonthStartTimestamp;
      this.date[3].endTime = lastMonthEndTimestamp;
    },
    selectDate(i) {
      if (this.dateVal === i) return;
      this.dateVal = i;
      this.dateLoading = true;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getPromoterInfo();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.promotion {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 46px;
  main {
    flex: 1;
    background: linear-gradient(
      180deg,
      #5da5ff 0%,
      #56a2ff 5%,
      #abcbfd 15%,
      #f5f6fa 30%
    );
    padding: 0 15px;
    .user_info {
      margin-top: 30px;
      color: #fff;
      .info {
        display: flex;
        .avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 12px;
        }
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .Lv {
            display: flex;
            align-items: center;
          }
        }
      }
      .upgrades_tips {
        margin-top: 12px;
        b {
          color: #f91c12;
          font-size: 16px;
        }
        span {
          font-size: 12px !important;
        }
      }
    }
    .box {
      padding: 24px 15px;
      background: #fff;
      box-shadow: 0px 1px 4px 1px rgba(16, 139, 255, 0.16);
      border-radius: 10px;
      margin-bottom: 12px;
    }
    .promotion_data {
      margin-top: 24px;

      > div {
        display: flex;
        justify-content: space-between;
      }
      .accumulate {
        p {
          color: #999999;
          line-height: 20px;
          margin-bottom: 8px;
        }
        h3 {
          font-size: 24px;
          line-height: 33px;
        }
        ::v-deep .van-button {
          height: 32px;
          padding: 0 13px;
          .van-button__text {
            display: flex;
            align-items: center;
          }
          .svg-icon {
            font-size: 10px;
            margin-left: 4px;
          }
        }
      }
      .commission {
        background: #f5f6fa;
        padding: 12px 15px;
        line-height: 20px;
        border-radius: 5px;
        margin-top: 26px;
        .text {
          color: #999999;
          margin-bottom: 8px;
        }
        .price {
          font-weight: bold;
        }
      }
      .performance {
        margin-top: 32px;
        padding-left: 15px;
        padding-right: 43px;
        .text {
          color: #999999;
          line-height: 20px;
          margin-bottom: 8px;
        }
        .num {
          font-size: 20px;
          line-height: 28px;
          font-weight: 800;
        }
      }
    }
    .add_commission {
      .date {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        p {
          width: 20.5%;
          text-align: center;
          line-height: 20px;
          padding: 6px;
          border-radius: 99px;
          background: #f5f6fa;
          transition: 0.2s all;
        }
      }
      .content {
        position: relative;
        .text {
          color: #999999;
          line-height: 20px;
          margin-bottom: 8px;
        }
        .num {
          font-size: 20px;
          color: #fb4e10;
          line-height: 28px;
        }
        .to_be_settled {
          padding: 6px 15px;
          border-radius: 5px;
          background: #f5f6fa;
          margin-top: 12px;
        }
        .performance {
          margin-top: 32px;
          display: flex;
          justify-content: space-between;
          padding-right: 43px;
        }
        .van-loading {
          position: absolute;
          inset: 0;
          background: rgba($color: #fff, $alpha: 0.75);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .my_promotion_order {
      padding: 15px;
      background: #fff;
      box-shadow: 0px 1px 4px 1px rgba(16, 139, 255, 0.16);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      line-height: 20px;
      a {
        color: #333333;
        display: flex;
        align-items: center;
        .svg-icon {
          font-size: 10px;
          margin-left: 4px;
        }
      }
    }
    .see_noun_explanation {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px 0;
      color: #333333;
      .svg-icon {
        font-size: 10px;
        margin-left: 4px;
      }
    }
  }
  .share_tips {
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 15px;

    div {
      display: flex;
      align-items: center;
      background: #333;
      width: 100%;
      border-radius: 100px;
      padding: 10px 15px;
      color: #fff;
      button {
        flex-shrink: 0;
        width: 60px;
        height: 32px;
        background: #fff;
        color: #333333;
        border-radius: 16px;
        border: none;
      }
      .svg-icon {
        margin-left: 8px;
      }
    }
  }
}
</style>
