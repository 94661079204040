<template>
  <div class="certified_promoter">
    <main>
      <img src="@/assets/images/rz.png" class="rz" />
      <div class="status">
        <div class="line"></div>
        <div class="title">
          <p><span style="background: #3b7afb">1</span></p>
          <p>实名认证</p>
        </div>
        <div class="title">
          <p><span :style="status ? 'background: #3b7afb' : ''">2</span></p>
          <p>协议推广</p>
        </div>
      </div>
      <template v-if="!status">
        <van-form
          @submit="onSubmit"
          :scroll-to-error="true"
          validate-trigger="onSubmit"
          ref="ruleForm"
        >
          <!-- :show-error-message="false" -->
          <div class="form_top">
            <div class="form" style="padding-bottom: 0.18rem">
              <h3>证件信息</h3>
              <van-field
                v-model="idCardName"
                name="idCardName"
                label="姓名"
                placeholder="请输入证件姓名"
                :rules="[{ required: true, message: '请输入真实姓名' }]"
              />
              <van-field
                v-model="idCardNumber"
                name="idCardNumber"
                label="证件号码"
                placeholder="请输入证件号码"
                :rules="Rules.idCardNumber"
              />
              <van-field
                readonly
                clickable
                name="idCardStartDate"
                :value="idCardStartDate"
                label="证件生效日期"
                placeholder="请选择"
                @click="showPicker = true"
                :rules="[{ required: true, message: '请选择证件生效日期' }]"
              />
              <van-popup v-model="showPicker" position="bottom">
                <van-datetime-picker
                  @confirm="onConfirm"
                  @cancel="showPicker = false"
                  type="date"
                  title="选择年月日"
                  :min-date="minDate"
                  :max-date="maxDate"
                />
              </van-popup>
              <van-field
                readonly
                clickable
                name="idCardStartDate"
                :value="idCardEndDate"
                label="证件失效日期"
                placeholder="请选择"
                @click="showPicker2 = true"
                :rules="[{ required: true, message: '请选择证件失效日期' }]"
              />
              <van-popup v-model="showPicker2" position="bottom">
                <van-datetime-picker
                  @confirm="onConfirm2"
                  @cancel="showPicker2 = false"
                  type="date"
                  title="选择年月日"
                  :min-date="minDate"
                  :max-date="maxDate"
                />
              </van-popup>
              <van-field
                name="uploader"
                class="van-cell-pirce2"
                label-width="auto"
              >
                <template #input>
                  <van-uploader
                    v-model="idCardCopy"
                    max-count="1"
                    :after-read="afterRead"
                  >
                    <template #default>
                      <div class="top">
                        <svg-icon icon-class="id_card_zm" />
                      </div>
                      <div class="bottom">
                        <img src="@/assets/images/camera_icon.png" alt="" />
                        点击上传身份证正面
                      </div>
                    </template>
                  </van-uploader>
                  <van-uploader
                    v-model="idCardNational"
                    max-count="1"
                    :after-read="afterRead2"
                  >
                    <template #default>
                      <div class="top">
                        <svg-icon icon-class="id_card_fm" />
                      </div>
                      <div class="bottom">
                        <img src="@/assets/images/camera_icon.png" alt="" />
                        点击上传身份证反面
                      </div>
                    </template>
                  </van-uploader>
                </template>
              </van-field>
            </div>
            <div class="form" style="padding-bottom: 0.36rem">
              <h3>绑定银行卡信息</h3>
              <van-field
                v-model="settleAcctName"
                name="settleAcctName"
                label="银行卡账户名称"
                placeholder="请输入账户名称"
                :rules="[{ required: true, message: '请输入账户名称' }]"
              />
              <van-field
                v-model="settleAcctBankAcctNo"
                name="settleAcctBankAcctNo"
                label="银行卡号"
                placeholder="请输入银行卡号"
                label-width="auto"
                type="digit"
                :rules="Rules.settleAcctBankAcctNo"
              />
            </div>
            <div class="form">
              <h3>账户安全信息</h3>
              <van-field
                v-model="password"
                name="password"
                type="password"
                label="设置交易密码"
                placeholder="请输入交易密码"
                maxlength="6"
                :rules="Rules.password"
              />
              <van-field
                v-model="password2"
                name="password2"
                type="password"
                label="确认交易密码"
                placeholder="请再次输入交易密码"
                maxlength="6"
                :rules="Rules.password2"
              />
              <van-field
                v-model="mobileNumber"
                name="mobileNumber"
                label="交易手机号"
                placeholder="请输入交易手机号"
                type="digit"
                :rules="Rules.mobileNumber"
                maxlength="11"
              />
              <van-field
                v-model="smsCode"
                label="短信验证码"
                :rules="Rules.smsCode"
                ref="smsCode"
                maxlength="6"
              >
                <template #button>
                  <span
                    style="color: #108bff"
                    v-show="!codeShow"
                    @click="getCode"
                    >发送验证码</span
                  >
                  <span v-show="codeShow">{{ codeShow }}s后再获取</span>
                </template>
              </van-field>
            </div>
          </div>
          <div class="form_button">
            <div class="protocol" @click="checkbox = !checkbox">
              <van-checkbox
                v-model="checkbox"
                icon-size="12px"
                shape="square"
              />
              本人已阅读<a
                href="https://origmall.cn/Agreement/BankAccountServiceAgreement.pdf"
                target="_blank"
                >《正品汇用户开立银行账户服务协议》</a
              >并同意授权正品汇平台合作协议银行资金管理服务协议
            </div>
            <van-button
              round
              block
              type="info"
              native-type="submit"
              color="#fff"
              class="submit_button"
            >
              确认
            </van-button>
          </div>
        </van-form>
      </template>
      <template v-else>
        <div class="protocol_2">
          <p @click="checkbox2 = !checkbox2">
            <van-checkbox v-model="checkbox2" icon-size="12px" shape="square" />
            本人已阅读并同意<br />
            <a
              target="_blank"
              href="https://origmall.cn/Agreement/PromotePart-timeFranchiseAgreements.pdf"
              >《正品汇个人加盟协议（推广兼职）》</a
            >
          </p>
          <van-button
            round
            type="info"
            native-type="submit"
            color="#108BFF"
            class="protocol_button"
            @click="submitAnApplication"
          >
            提交申请
          </van-button>
        </div>
      </template>
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatDay2 } from "@/utils/validate";
export default {
  name: "promotion-registration",
  computed: {
    ...mapState("user", ["isLogin", "userInfo", "promoter"]),
  },
  data() {
    var validatePass = (value, rule) => {
      rule.message = "请填写密码";
      if (value.length < 6) {
        rule.message = "密码最少为6位!";
        return false;
      } else if (this.password2 !== "" || value === "") {
        this.$refs.ruleForm.validateField("password2");
      }
    };
    var validatePass2 = (value, rule) => {
      rule.message = "请填写确认密码";
      if (this.password != "" && value !== this.password) {
        rule.message = "两次输入密码不一致!";
        return false;
      }
    };
    return {
      showPicker: false,
      showPicker2: false,
      minDate: new Date(1940, 0, 1),
      maxDate: new Date(2099, 11, 1),
      date: "",
      date2: "",
      codeShow: 0,
      status: 0,

      idCardIsLongTimeValidate: false, //证件照是否长期有效
      idCardName: "", //姓名
      idCardNumber: "", //身份证号码
      idCardStartDate: "", //证件开始时间
      idCardEndDate: "", //证件结束时间
      idCardCopy: [], //证件人面照
      idCardNational: [], //证件国徽照
      settleAcctName: "", //开户名称
      settleAcctBankAcctNo: "", //银行账户
      password: "", //交易授权码
      password2: "",
      mobileNumber: "", //手机号码
      smsCode: "", //短信验证码

      // idCardIsLongTimeValidate: false, //证件照是否长期有效
      // idCardName: "zhangsan", //姓名
      // idCardNumber: "450481198903181614", //身份证号码
      // idCardStartDate: "2022-01-02", //证件开始时间
      // idCardEndDate: "2032-01-02", //证件结束时间
      // idCardCopy: [
      //   { url: "http://oa.realbrand.net/5173a8c6bd0c41f1aa29b495fa6fe8f5.jpg" },
      // ], //证件人面照
      // idCardNational: [
      //   { url: "http://oa.realbrand.net/b0cb844af7894fceba0fbb5bc42df746.jpg" },
      // ], //证件国徽照
      // settleAcctName: "zhangsan", //开户名称
      // settleAcctBankAcctNo: "6217002430009829379", //银行账户
      // password: "123456", //交易授权码
      // password2: "123456",
      // mobileNumber: "15528886113", //手机号码
      // smsCode: "999999", //短信验证码
      checkbox: false,
      checkbox2: false,

      Rules: {
        idCardNumber: [
          {
            required: true,
            message: "请输入证件号码",
          },
          {
            validator: (value) => {
              return /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/.test(
                value
              );
            },

            message: "请输入正确格式的证件号码",
          },
        ],
        settleAcctBankAcctNo: [
          {
            required: true,
            message: "请输入银行卡号",
          },
          {
            validator: (value) => {
              return /^[1-9]\d{9,29}$/.test(value);
            },

            message: "请输入正确格式的银行卡号",
          },
        ],
        password: [
          {
            required: true,
            validator: validatePass,
            message: "请输入交易密码",
          },
        ],
        password2: [
          {
            required: true,
            validator: validatePass2,
            message: "请再次填写密码",
          },
        ],
        mobileNumber: [
          {
            required: true,
            message: "手机号码不能为空",
          },
          {
            validator: (value) => {
              return /^(?:(?:\+|00)86)?1\d{10}$/.test(value);
            },
            message: "请输入正确格式的手机号码",
          },
        ],
        smsCode: [
          {
            required: true,
            validator: (val, rule) => {
              if (val.length < 6) {
                console.log(val);
                rule.message = "请输入正确验证码";
                return false;
              }
            },
            message: "请填写验证码",
          },
        ],
      },
    };
  },

  created() {
    if (this.$route.query.id)
      sessionStorage.setItem("userUuid", this.$route.query.id);
    // /register?redirect_uri=${encodeURIComponent($route.fullPath)}`
    if (!this.isLogin) {
      this.$router.replace(`/login?redirect_uri=${this.$route.fullPath}`);
      return;
    }

    if (this.promoter) {
      this.Toast.fail("已经是推广员啦");
      this.$router.replace("/promotion");
    } else {
      this.queryApplications();
    }
  },
  methods: {
    onSubmit(values) {
      if (!this.checkbox) {
        this.Toast({
          message: "请勾选协议",
        });
      } else if (!this.idCardCopy.length) {
        this.Toast.fail("请上传身份证正面照片");
      } else if (!this.idCardNational.length) {
        this.Toast.fail("请上传身份证反面照片");
      } else {
        this.cusApplications();
      }
    },
    async queryApplications() {
      this.Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      await this.$API.user
        .queryApplications({
          object: {
            serviceType: 1,
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
          if (res.data.data.status === 1) this.status++;
          console.log(this.status);
          this.Toast.clear();
        })
        .catch((e) => {
          this.Toast.clear();
          this.Toast.fail(e.message);
        });
    },
    async cusApplications() {
      this.Toast.loading({
        duration: 0,
        message: "上传中...",
        forbidClick: true,
      });
      await this.$API.user
        .cusApplications({
          object: {
            idCardCopy: this.idCardCopy[0].url,
            idCardEndDate: this.idCardEndDate,
            idCardIsLongTimeValidate: this.idCardIsLongTimeValidate,
            idCardName: this.idCardName,
            idCardNational: this.idCardNational[0].url,
            idCardNumber: this.idCardNumber,
            idCardStartDate: this.idCardStartDate,
            mobileNumber: this.mobileNumber,
            password: this.password,
            settleAcctBankAcctNo: this.settleAcctBankAcctNo,
            settleAcctName: this.settleAcctName,
            smsCode: this.smsCode,
            userId: this.userInfo.id,
            serviceType: 1, //服务商类型|1：广银|2：江苏银行

            // "cusId": "string", //Cus ID
            // "settleAcctBankBranchCode": "string", //开户银行联行号
            // "settleAcctBankBranchName": "string", //开户支行名称
            // "settleAcctBankCode": "string", //开户银行编码
            // "settleAcctBankName": "string", //开户银行名称
          },
        })
        .then((res) => {
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
          if (res.data.data.status != "succeeded")
            throw Error(res.data.data.issue.issue);
          this.status++;
          this.Toast.clear();
        })
        .catch((e) => {
          this.Toast.clear();
          this.Toast({
            message: e.message,
            type: "fail",
            duration: 0,
            // forbidClick: true,
            closeOnClickOverlay: true,
            overlay: true,
            closeOnClick: true,
          });
          console.error(e.message);
          throw Error(e.message);
        });
    },
    async submitAnApplication() {
      if (!this.checkbox2) {
        this.Toast({
          message: "请勾选协议",
        });
        return;
      }
      this.Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      await this.$API.user
        .addPromoter({
          object: {
            id: this.userInfo.id,
          },
        })
        .then((res) => {
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
          this.Toast.clear();
          this.$store.dispatch("user/queryPromoterById");
          this.$router.replace("/promotion/successfulCertification");
        })
        .catch((e) => {
          this.Toast.clear();
          this.Toast.fail(e.message);
        });
    },
    //获取验证码
    getCode() {
      this.$refs.ruleForm.validate("mobileNumber").then(async () => {
        await this.$API.user
          .sendSmsCodesByMobile({
            mobileNumber: this.mobileNumber,
            serviceType: 1,
          })
          .then((res) => {
            if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
            this.Toast.success("发送成功");
            this.codeShow = 59;
            this.t = setInterval(() => {
              if (this.codeShow) {
                this.codeShow--;
              } else {
                clearInterval(this.t);
              }
            }, 1000);
          })
          .catch((e) => {
            this.Toast.fail(e.message);
          });
      });
    },

    onConfirm(time) {
      this.idCardStartDate = formatDay2(new Date(time).getTime());
      this.showPicker = false;
    },
    onConfirm2(time) {
      this.idCardEndDate = formatDay2(new Date(time).getTime());
      this.showPicker2 = false;
    },
    afterRead(file) {
      var formdata = new FormData();
      formdata.append("file", file.file);
      this.$API.file
        .imageUpload(formdata)
        .then((res) => {
          console.log(res);
          this.idCardCopy[0].url = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.idCardCopy = [];
          this.Toast.fail("图片上传失败");
        });
      // axios
      //   .post("https://upload.realbrand.net/image/imageUpload", formdata)
      //   .then((res) => {
      //     console.log(res.data.data);
      //     this.frontId[0].url = res.data.data;
      //     console.log(this.frontId);
      //   });
    },
    afterRead2(file) {
      var formdata = new FormData();
      formdata.append("file", file.file);
      this.$API.file
        .imageUpload(formdata)
        .then((res) => {
          console.log(res);
          this.idCardNational[0].url = res.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.idCardNational = [];
          this.Toast.fail("图片上传失败");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.certified_promoter {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 46px;
  main {
    flex: 1;
    background: #1a8aff;
    padding: 0 15px 30px;
    position: relative;
    .rz {
      position: absolute;
      top: -10px;
      right: 0;
    }
    .status {
      display: flex;
      position: relative;
      margin: 24px 0;
      .line {
        position: absolute;
        top: 13px;
        left: 0;
        right: 0;
        margin: 0 33px;
        height: 6px;
        background: #fff;
        border-radius: 7px 7px 7px 7px;
      }
      .title {
        position: relative;
        z-index: 1;
        width: 50%;
        text-align: center;
        color: #fff;
        p:nth-child(1) {
          width: 32px;
          height: 32px;
          background: #fff;
          margin: 0 auto;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
          span {
            background: #b1b1b1;
            border-radius: 50%;
            width: 100%;
            line-height: 24px;
            font-size: 17px;
            font-weight: bold;
          }
        }
        p:nth-child(2) {
          margin-top: 8px;
          font-size: 16px;
        }
      }
    }
    .van-form {
      position: relative;
      z-index: 1;
      .form_top {
        background: #fff;
        border-radius: 10px;
        padding: 24px 15px;
      }
      ::v-deep .form {
        h3 {
          margin-bottom: 16px;
        }
        .van-cell {
          background: #f6f6f6;
          border-radius: 22px;
          margin-bottom: 14px;
          .van-cell__title {
            width: auto;
            min-width: 98px;
          }
        }
        .van-cell:last-child {
          margin: 0;
        }
        .van-cell-pirce2 {
          background: #fff;
          padding: 0;
          border-radius: 0;
          padding: 18px 0;
          .van-field__control {
            .van-uploader:nth-child(1) {
              padding-right: 7.5px;
            }
            .van-uploader:nth-child(2) {
              padding-left: 7.5px;
            }
            .van-uploader__wrapper {
              display: block;
            }
            .van-uploader {
              .top {
                padding: 10px 0;
                font-size: 70px;
              }
            }
          }
        }
        .van-cell-pirce {
          .van-uploader__wrapper {
            padding: 0 18px;
          }
          .van-uploader__input-wrapper {
            flex: 1;
            .top {
              font-size: 72px;
              padding: 50px 0;
            }
          }
        }
        .van-cell-pirce,
        .van-cell-pirce2 {
          .van-uploader__preview {
            flex: 1;
            margin: 0;
            .van-image {
              width: auto;
              height: auto;
            }
          }
          .van-cell__value {
            .van-uploader {
              width: 50%;
              .top {
                border-radius: 5px 5px 0 0;
                border: 1px solid #3e93fe;
                border-bottom: none;
                text-align: center;
              }
              .bottom {
                line-height: 20px;
                font-size: 12px;
                color: #ffffff;
                padding: 5px 0;
                border-radius: 0 0 5px 5px;
                background: #3e93fe;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  width: 14px;
                  margin-right: 4px;
                }
              }
            }
          }
        }

        .van-cell-bank-card {
          .van-field__control {
            justify-content: space-between;
            p:nth-child(1) {
              color: #999999;
              span {
                color: #f61423;
              }
            }
            // p:nth-child(2) {
            // }
          }
        }
      }
    }
    .protocol {
      margin: 15px 0 34px;
      color: #fff;
      line-height: 22px;
      a {
        color: #fff;
        text-decoration: underline;
      }
      ::v-deep.van-checkbox {
        display: inline-block;
        .van-icon-success {
          border-color: #fff;
        }
        .van-checkbox__icon--checked .van-icon-success {
          background: #fff;
          color: #1a8aff;
        }
        .van-icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .submit_button {
      font-size: 16px;
      color: #108bff !important;
    }
    .protocol_2 {
      position: relative;
      z-index: 1;
      background: #ffffff;
      border-radius: 10px;
      text-align: center;
      padding: 34px 0 24px;
      p {
        line-height: 24px;
        color: #666666;
        ::v-deep .van-checkbox {
          display: inline-block;
          .van-icon {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        a {
          color: #108bff;

          font-size: 16px;
        }
      }
    }
    .protocol_button {
      margin-top: 54px;
      padding: 0 42px;
    }
  }
}
</style>
<style lang="scss"></style>
